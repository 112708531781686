.react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  background: #d7e0e4;
  border-radius: 7.55px;
  border: none;
}

.react-multi-carousel-dot--active button {
  background: #3374ed;
}
