/* we can modify styles of the flashcard here */

.flashcard h1 {
  @apply text-3xl my-2 font-nunito;
}

.flashcard h2 {
  @apply text-2xl my-2 font-nunito;
}

.flashcard h3 {
  @apply text-xl my-2 font-nunito;
}

.flashcard p {
  @apply text-base font-nunito;
}

.flashcard ul {
  @apply list-disc pl-5;
}

.flashcard ol {
  @apply list-decimal pl-5;
}

.flashcard li {
  @apply mb-2;
}

.flashcard table {
  @apply w-full border-collapse;
}

.flashcard th,
td {
  @apply border px-4 py-2;
}

.flashcard thead {
  @apply bg-grey-200;
}

.flashcard tbody tr:nth-child(odd) {
  @apply bg-grey-100;
}
.flashcard a {
  @apply text-blue-500 hover:text-blue-700 underline;
}

.flashcard img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img_full_width {
  @apply w-full h-auto;
}
