:root {
  --border-width: 4px;
  --shadow-color-1: rgba(59, 130, 246, 0.8);
  --shadow-color-2: rgba(148, 211, 162, 0.6);
  --shadow-color-3: rgba(251, 191, 36, 0.8);
  --shadow-color-4: rgba(249, 115, 22, 0.6);
  --shadow-color-5: rgba(231, 129, 215, 0.8);
  --shadow-color-6: rgba(167, 140, 192, 0.6);
  --box-shadow-px: 20px;
  --grey100: #f5f7f8;
  --green500: #29a745;
  --blue50: #ebf1fd;
  --blue300: #709ef2;
  --blue400: #4782ef;
  --blue500: #3375ed;
  --blue600: #2e68d5;
  --blue700: #2451a6;
}

@keyframes shadowColorChange1 {
  0% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-1);
  }
  16.67% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-2);
  }
  33.33% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-3);
  }
  50% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-4);
  }
  66.67% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-5);
  }
  83.33% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-6);
  }
  100% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-1);
  }
}

@keyframes shadowColorChange2 {
  0% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-2);
  }
  16.67% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-3);
  }
  33.33% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-4);
  }
  50% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-5);
  }
  66.67% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-6);
  }
  83.33% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-1);
  }
  100% {
    box-shadow: 0 0 var(--box-shadow-px) var(--shadow-color-2);
  }
}

.border-animation {
  position: relative;
  z-index: 1;
}

.border-animation::before,
.border-animation::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: calc(4 * var(--border-width));
  z-index: -1;
}

.border-animation::before {
  animation: shadowColorChange1 4s linear infinite;
}

.border-animation::after {
  animation: shadowColorChange2 4s linear infinite;
}
.closed {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.open {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.status-update {
  display: flex;
  flex-direction: column;
}

.caret {
  font-size: 1.5rem;
  padding-bottom: 4px;
  transition: 0.3s ease;
  color: black;
  font-weight: normal;
}

.reasoning-title-container {
  display: flex;
  background-color: var(--blue50);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 0px 8px;
}
.guideline .caret.open {
  transform: rotate(180deg);
}
.guideline .caret.closed {
  transform: rotate(360deg);
}
.status-update div:not(.reasoning-title-container) {
  padding-left: 16px;
}
.status-update-text {
  margin-top: 16px;
  font-size: 1.1rem;
  color: var(--blue500);
  font-style: italic;
  font-weight: 600;
  text-align: center;
  animation: colorChange 3s infinite alternate;
}

@keyframes colorChange {
  0% {
    color: var(--blue700);
  }
  25% {
    color: var(--blue600);
  }
  50% {
    color: var(--blue500);
  }
  75% {
    color: var(--blue400);
  }
  100% {
    color: var(--blue300);
  }
  125% {
    color: var(--blue400);
  }
  150% {
    color: var(--blue500);
  }
  175% {
    color: var(--blue600);
  }
}
.ref-title-container {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
}
.ref-title-container .caret {
  margin-left: auto;
}
.caret:hover {
  font-size: 1.8rem;
}
.useful-links-title-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
.useful-links-title-container .caret.closed {
  margin-top: 8px;
}
.useful-links-title-container .caret.open {
  margin-bottom: 8px;
}

.guideline {
  background-color: var(--blue50);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}
.snippet-box {
  border: 1px solid var(--green500);
  background-color: var(--grey100);
  padding: 8px 8px 4px;
  border-radius: 4px;
}
